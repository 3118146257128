export const locale = {
    "es": {
        "or": "Ó",
        "back": "Regresar",
        "advanced": "Avanzado",
        "close": "Cerrar",
        "no_results": "Sin resultados",
        "search_placeholder": "Buscar archivos",
        "about_uw": "Acerca de Upload Widget",
        "menu": {
            "files": "Mis archivos",
            "web": "Dirección Web",
            "camera": "Cámara",
            "gsearch": "Buscar imagen",
            "gdrive": "Google Drive",
            "dropbox": "Dropbox",
            "facebook": "Facebook",
            "instagram": "Instagram",
            "shutterstock": "Shutterstock",
            "istock": "iStock"
        },
        "selection_counter": {
            "selected": "Seleccionado"
        },
        "actions": {
            "upload": "Subir",
            "clear_all": "Limpiar todo",
            "log_out": "Cerrar"
        },
        "notifications": {
            "general_error": "Ocurrió un error.",
            "general_prompt": "¿Estás seguro?",
            "limit_reached": "No puedes seleccionar más archivos.",
            "invalid_add_url": "La URL debe ser válida.",
            "invalid_public_id": "El ID público no puede contener \\,?,&,#,%,<,>.",
            "no_new_files": "Los archivos ya han sido cargados.",
            "image_purchased": "Imagen comprada",
            "video_purchased": "Video comprado",
            "purchase_failed": "Compra fallida. Por favor reintenta.",
            "service_logged_out": "Servicio cerrado debido a un error",
            "great": "Genial"
        },
        "advanced_options": {
            "public_id_ph": "ID público",
            "tags_ph": "Agregar etiqueta",
            "add_new": "Agregar nueva etiqueta:",
            "upload_preset_placeholder": "Subir pre-configuración"
        },
        "landscape_overlay": {
            "title": "Modo vertical no soportado",
            "description": "Gira tu celular a modo horizontal para continuar."
        },
        "queue": {
            "title": "Fila de carga",
            "title_uploading_with_counter": "Cargando {{num}} elementos",
            "title_uploading": "Cargando elementos",
            "mini_title": "Cargados",
            "mini_title_uploading": "Cargado",
            "show_completed": "Mostrar completado",
            "retry_failed": "Reintento fallido",
            "abort_all": "Cancelar todos",
            "upload_more": "Cargar mas",
            "done": "Listo",
            "mini_upload_count": "{{num}} cargados",
            "mini_failed": "{{num}} fallidos",
            "statuses": {
                "uploading": "Cargando…",
                "error": "Error",
                "uploaded": "Listo",
                "aborted": "Cancelado"
            }
        },
        "uploader": {
            "filesize": {
                "na": "N/A",
                "b": "{{size}} Bytes",
                "k": "{{size}} KB",
                "m": "{{size}} MB",
                "g": "{{size}} GB",
                "t": "{{size}} TB"
            },
            "errors": {
                "file_too_large": "El tamaño del archivo ({{size}}) excede el máximo permitido ({{allowed}})",
                "max_dimensions_validation": "Las dimensiones de la imagen ({{width}}X{{height}}) son mayores del máximo permitido: ({{maxWidth}}X{{maxHeight}})",
                "min_dimensions_validation": "Las dimensiones de la imagen ({{width}}X{{height}}) son menores del mínimo permitido: ({{minWidth}}X{{minHeight}})",
                "unavailable": "NA",
                "max_number_of_files": "Número de archivos excedido",
                "allowed_formats": "Formato de archivo no permitido",
                "max_file_size": "El archivo es demasiado grande",
                "min_file_size": "El archivo es muy pequeño"
            },
            "close_mid_upload": "Hay cargas en proceso. Da clic en OK para cancelarlas."
        },
        "crop": {
            "title": "Cortar",
            "crop_btn": "Cortar",
            "skip_btn": "Omitir",
            "reset_btn": "Reiniciar",
            "close_btn": "Si",
            "close_prompt": "Al cerrar se cancelarán todas las cargas, ¿estás seguro?",
            "image_error": "Error al cargar imagen",
            "corner_tooltip": "Arrastra la esquina para ajustar tamaño",
            "handle_tooltip": "Arrastra el control para ajustar tamaño"
        },
        "camera": {
            "capture": "Capturar",
            "cancel": "Cancelar",
            "take_pic": "Toma una foto para cargarla",
            "explanation": "Asegúrate que tu cámara esté conectada y que tu navegador permite capturas de pantalla. Cuando estés listo, da clic en Capturar.",
            "camera_error": "Error al acceder a la cámara",
            "retry": "Reintentar cámara",
            "file_name": "Cámara_{{time}}"
        },
        "dropbox": {
            "no_auth_title": "Carga archivos de tu cuenta de Dropbox.",
            "no_auth_action": "Conectar a Dropbox",
            "no_photos": "Sin fotos",
            "no_files": "Sin archivos",
            "root_crumb": "Raíz",
            "list_headers": {
                "select": "Seleccionado",
                "name": "Nombre",
                "modified": "Modificado"
            },
            "menu": {
                "browse": "Buscar",
                "recent": "Reciente"
            },
            "authenticating": "Autenticando..."
        },
        "facebook": {
            "no_photos": "No hay fotos...",
            "no_auth_title": "Carga fotos de tu cuenta de Facebook.",
            "no_auth_action": "Conectar a Facebook",
            "no_auth_statement": "No publicaremos nada sin tu permiso.",
            "album_subtitle": "{{count}} fotos",
            "menu": {
                "uploaded": "Tus fotos",
                "tagged": "Fotos de ti",
                "albums": "Álbumes"
            }
        },
        "google_drive": {
            "no_auth_title": "Cargar archivos de tu Drive de Google.",
            "no_auth_action": "Conectar a Google Drive",
            "search": {
                "placeholder": "Buscar…",
                "reset": "Reiniciar búsqueda"
            },
            "grid": {
                "folders": "Carpetas",
                "files": "Archivos",
                "empty_folder": "Esta carpeta está vacía."
            },
            "recent": "Reciente",
            "starred": "Marcados",
            "my_drive": "Mi Drive",
            "shared_drive": "Drives compartidos",
            "search_results": "Resultados de búsqueda",
            "shared_with_me": "Compartidos conmigo",
            "name": "Nombre",
            "modifiedTime": "Última modificación",
            "modifiedByMeTime": "Última modificación por mi",
            "viewedByMeTime": "Última vez abierto por mi",
            "notifications": {
                "retrieve_failed": "Error al cargar datos de tu Drive de Google."
            }
        },
        "image_search": {
            "main_title": "Buscar imagen",
            "inputPlaceholder": "Buscar imágenes",
            "customPlaceholder": "Buscar {{site}}",
            "show_options": "Mostrar opciones",
            "hide_options": "Ocultar opciones",
            "filters_title": "Sitio",
            "all": "todo",
            "rights": "Derechos de uso:",
            "rights_options": {
                "not_filtered": "no filtrado por licenciamiento",
                "free": "libre para usar o compartir",
                "free_com": "libre para usar o compartir, aún comercialmente",
                "free_mod": "libre para usar o compartir o modificar",
                "free_mod_com": "libre para usar, compartir o modificar, aún comercialmente"
            },
            "search_error": "Error en búsqueda, por favor intenta de nuevo."
        },
        "instagram": {
            "no_auth_title": "Carga fotos de tu cuenta de Instagram.",
            "no_auth_action": "Conectar a Instagram",
            "header_title": "Tus fotos recientes de Instagram",
            "authenticating": "Autenticando…"
        },
        "local": {
            "browse": "Buscar",
            "dd_title_single": "Arrastra y suelta tu archivo aquí",
            "dd_title_multi": "Arrastra y suelta archivos aquí",
            "drop_title_single": "Arrastra y suelta un archivo para cargarlo",
            "drop_title_multiple": "Arrastra y suelta archivos para cargarlos"
        },
        "shutterstock": {
            "no_auth_title": "Carga archivos de tu cuenta de Shutterstock.",
            "toggle_filters_button": "Filtros",
            "no_auth_action": "Conectar a Shutterstock",
            "authenticating": "Autenticando…",
            "statement": "Shutterstock ofrece la mejor calidad, imágenes sin regalías, fotos, vectores, ilustraciones, videos y música para casi cualquier aplicación.",
            "reg_link_text": "Da clic aquí para crear tu cuenta en Shutterstock",
            "next_btn": "Siguiente",
            "media_types": {
                "images": "Imágenes",
                "videos": "Videos"
            },
            "filters": {
                "more_label": "Mas",
                "sort_options": {
                    "label": "Ordener por",
                    "newest": "Nuevo",
                    "relevance": "Relevante",
                    "popular": "Popular",
                    "random": "Aleatorio"
                },
                "people": {
                    "label": "Gente",
                    "only_images_with_people": "Sólo imágenes con gente",
                    "only_videos_with_people": "Sólo videos con gente"
                },
                "gender": {
                    "label": "Genero",
                    "male": "Hombre",
                    "female": "Mujer",
                    "both": "Ambos"
                },
                "size": {
                    "label": "Tamaño",
                    "small": "Pequeño",
                    "medium": "Mediano",
                    "large": "Grande"
                },
                "clear": "limpiar",
                "orientation": {
                    "label": "Orientación",
                    "horizontal": "Horizontal",
                    "vertical": "Vertical"
                },
                "color": "Color",
                "colors": {
                    "red": "rojo",
                    "orange": "naranja",
                    "amber": "ambar",
                    "yellow": "amarillo",
                    "lime": "lima",
                    "green": "verde",
                    "teal": "verde azulado",
                    "turquoise": "turquesa",
                    "aqua": "aqua",
                    "azure": "azul tenue",
                    "blue": "azul",
                    "purple": "púrpura",
                    "orchid": "orquídea",
                    "magenta": "magenta"
                },
                "safe": "Seguro",
                "all_categories": "Todas las categorías",
                "types": {
                    "all": "Todos los tipos de imágenes",
                    "illustration": "Ilustración",
                    "photo": "Foto",
                    "vector": "Vector"
                },
                "duration": {
                    "label": "Duración",
                    "short": "Corto",
                    "short_tip": "< 4 minutos",
                    "long": "Largo",
                    "long_tip": "> 20 minutos"
                },
                "resolution": {
                    "label": "Resolución",
                    "standard_definition": "SD",
                    "standard_definition_tip": "Definición estándar",
                    "high_definition": "HD",
                    "high_definition_tip": "Alta definición",
                    "4k": "4k",
                    "4k_tip": "4k"
                }
            },
            "filter_summary": {
                "label": "Filtros: {{- summary}}",
                "gender": "sólo {{value}}",
                "people": "con {{value}} people",
                "color": "color: {{value}}",
                "category": "en {{- value}}",
                "unsafe": "no seguro"
            },
            "purchase_page": {
                "purchase_btn_label": "Comprar",
                "plans": {
                    "label": "Seleccionar plan",
                    "years": "años",
                    "days": "días",
                    "expired": "expirado",
                    "exceeded": "excedido",
                    "manage_plans": "Administra tus planes de Shutterstock...",
                    "no_plan": "Tu plan actual no incluye "
                },
                "format_and_size": {
                    "label": "Selecciona formato y tamaño"
                },
                "aspect_ratio": "Proporción",
                "author": "Autor",
                "description": "Descripción",
                "button_label": {
                    "purchase": "Comprar",
                    "acquire": "Comprar",
                    "next": "Siguiente",
                    "upload": "Cargar",
                    "purchasing": "Comprando…",
                    "downloading": "Descargando…"
                },
                "button_description": {
                    "next": "You already own this {{assetType}} Click Next to continue",
                    "upload": "You already own this {{assetType}} Click Upload to continue",
                    "purchasing": "Purchasing - This may take a few seconds...",
                    "downloading": "Downloading - This may take a few seconds..."
                },
                "duration": "Duración",
                "fps": "FPS",
                "sizes": {
                    "vector_eps": "Vector",
                    "small_jpg": "Pequeño",
                    "medium_jpg": "Mediano",
                    "huge_jpg": "Grande",
                    "web": "Web",
                    "sd": "SD",
                    "hd": "HD",
                    "4k": "4K"
                },
                "file_limit_exceeded": "Algunas opciones excedieron el tamaño límite.",
                "time_left": "{{time}} tiempo pendiente"
            },
            "purchase_preview": {
                "loading_preview": "Cargando vista previa",
                "open_in_new_window": "Abrir en nueva ventana"
            },
            "dictionary": {
                "image": "imagen",
                "video": "video"
            },
            "home_page": {
                "results_header": "Popular",
                "categories_header": "Categorías"
            },
            "search": {
                "placeholder": "Buscar…",
                "reset": "Reiniciar búsqueda"
            }
        },
        "i_stock": {
            "no_auth_title": "Cargar archivos de cuenta de iStock by Getty Images.",
            "no_auth_action": "Conectar a iStock",
            "no_auth_statement": "iStock by Getty Images es una de las tiendas de contenido de imágenes más importantes, ofreciendo millones de imágenes de primera calidad, elegidas manualmente a precios ridículamente bajos que sólo pueden ser obtenidos de nosotros.",
            "reg_link_text": "Da clic aquí para crear una cuenta de iStock by Getty Images",
            "search": {
                "placeholder": "Buscar…",
                "reset": "Reiniciar búsqueda"
            }
        },
        "url": {
            "inner_title": "URL público o archivo a cargar:",
            "input_placeholder": "http://remote.site.example/images/remote-image.jpg"
        },
        "metadataPage": {
            "title": "Valores estructurados de metadata",
            "subtitle": "1 archivo seleccionado",
            "subtitle_plural": "{{count}} archivos seleccionados",
            "tooltip": "Algunos campos de metadata requeridos están vacíos o los valores proporcionados son incorrectos.",
            "upload": "Cargar",
            "conflict_label": "Sobrescribir",
            "intro": {
                "openingText": "Puedes llenar los siguientes campos para agregar nueva metadata a tus archivos a ser cargados.",
                "conflictWarning": "Nota: Selecciona ‘Sobrescribir’ para los campos donde deseas aplicar los nuevos valores para archivos nuevos o a reemplazar.",
                "closingText": "Después de cargar, puedes modificar la metadata para archivos individuales desde la librería de medios."
            },
            "closePrompt": "¿Estás seguro de querer cerrar esta caja de diálogo? Tus archivos no serán cargados.",
            "backPrompt": "¿Estás seguro? Tu selección de archivos y su metadata asociada se perderá.",
            "approveCancel": "Si",
            "cancel": "Cancelar",
            "validationErrors": {
                "string": {
                    "min": "Deben ser al menos {{min}} caracteres.",
                    "max": "No pueden ser más de {{max}} caracteres.",
                    "minAndMax": "La longitud debe ser entre {{min}}-{{max}} caracteres.",
                    "regex": "Debe incluir solamente XXXX."
                },
                "integer": {
                    "lessThan": "Debe ser menos de {{less}}.",
                    "lessThanEqual": "No pueden ser más de {{max}}.",
                    "greaterThan": "Deben ser más de {{more}}.",
                    "greaterThanEqual": "Al menos deben ser {{min}}."
                },
                "number": {
                    "lessThan": "Deben ser menos de {{less}}.",
                    "lessThanEqual": "No pueden ser más de {{max}}.",
                    "greaterThan": "Deben ser más de {{more}}.",
                    "greaterThanEqual": "Deben ser al menos {{min}}."
                },
                "enum": {
                    "oneOf": "{{originalValue}} debe ser una de las opciones indicadas previamente."
                },
                "set": {
                    "oneOf": "{{originalValue}} debe ser una de las opciones indicadas previamente."
                },
                "date": {
                    "lessThan": "Debe ser antes de {{max}}.",
                    "lessThanEqual": "No puede ser después de {{max}}.",
                    "greaterThan": "Debe ser después de {{min}}.",
                    "greaterThanEqual": "No puede ser antes de {{min}}."
                },
                "stringError": "Debe ser texto.",
                "numberError": "Debe ser un número.",
                "integerError": "Debe ser un número.",
                "dateError": "Debe ser una fecha.",
                "enumError": "Elige una de las opciones indicadas.",
                "setError": "Elige al menos una de las opciones indicadas.",
                "required": "Este campo es requerido.",
                "integerTypeError": "Este es un campo para valores enteros.",
                "digitsLimitError": "Este número no puede contener más de 16 dígitos.",
                "unsupportedFields": "Por favor contacta a tu administrador, hay un problema con los siguientes campos de metadata: ",
                "unsupportedRequiredFields": "Por favor contacta a tu administrador, hay un problema con los siguientes campos de metadata obligatorios: "
            }
        }
    }
}