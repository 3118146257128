import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { UIConstants as UIConstantsEnglish, UIConstantsSpanish } from '../../constants';
import { NavTracHeader, RadioLabel, RadioButton, NavTracFooter, Loader } from '../../components/index';
import { get, submitCheckin} from '../../services/APIServices';
import { getFuelTypes } from './fuelType';
import { TRACKING_DETAILS_SCREEN, SUCCESS_DETAILS } from '../../constants/routes';
import { onGetQueryString } from '../../services/RouteServices';

const FuelTypeDetails = () => {

  const [direction, onSetDirection] = useState('ingated');
  const [checkinSite, onSetCheckinSite] = useState(null);
  const [checkinLocation, onSetCheckinLocation] = useState(null);
  const [language, onSetLanguage] = useState('en');
  const [UIConstants, onSetUIConstants] = useState(UIConstantsEnglish);
  const [fuelTypeId, onSelectFuelTypeId] = useState(null);
  const [isLoading, onSetLoading] = useState();
  const [error, onGetError] = useState();
  const [fuelTypeDetails, setFuelTypeDetails] = useState({});

  let history = useHistory();
  let location = useLocation();
  let driverData = location?.state

  const onPressBack = () => {
    let queryString = onGetQueryString(location?.search);
    history.push(`${TRACKING_DETAILS_SCREEN}?${queryString}`, { ...location.state, fuelTypeId, language });
  }

  const onSubmit = async () => {
    driverData.fuelTypeId =  fuelTypeId;

    try {
      onSetLoading(true)
      let response = await submitCheckin(driverData);
      if (typeof response?.data === 'object' && response?.data !== null) {
        onSetLoading(false)
        history.push(`${SUCCESS_DETAILS}`, { ...location.state });
      }
      else {
        onGetError(true)
      }
    } catch (error) {
      onGetError(true)
    }
  }

  const onLanguageChange = (lang) => {
    onSetLanguage(lang)
    if (lang !== 'en') {
      onSetUIConstants(UIConstantsSpanish)
    } else {
      onSetUIConstants(UIConstantsEnglish)
    }
  }
  const getFuelData = useCallback(async () => {
    try {
      let fuelTypes = await get('fuel_types')
      onSelectFuelTypeId(location?.state?.fuelTypeId || fuelTypes.data?.find((f) => (f.name = 'Diesel')).id)

      const fuelTypeDetails = getFuelTypes(fuelTypes?.data, UIConstants.FuelSubTypes, styles, location?.state?.language);
      setFuelTypeDetails(fuelTypeDetails)
    } catch (error) {
      onGetError(true)
    }
  }, [UIConstants, location])

  useEffect(() => {
    onLanguageChange(location.state.language)
    onSetDirection(location.state.direction)
    onSetCheckinSite(location.state.checkinSite)
    onSetCheckinLocation(location?.state?.checkinLocation)
    getFuelData()

  }, [location, getFuelData]);

  return (
    <div>
      <NavTracHeader
          pageName={UIConstants.pageName.fuelType}
          error={error}
          onSetLanguage={onLanguageChange}
          showLanguagePick={false}
          language={language}
          direction={UIConstants.direction[direction]}
          companyName={checkinSite?.company?.name || ''}
          checkinSite={checkinSite}
          siteName={checkinLocation?.name || checkinSite?.name || ''}
          siteAddress={checkinLocation?.meta?.address || checkinSite?.meta?.address}
      />
      {!error && <div>
        {isLoading ? <Loader /> :
          <div>
            <div className="radio-container" style={styles.radioContainer}>
              {fuelTypeDetails && fuelTypeDetails?.carbonFuel?.map(fuel =>
                  <RadioButton key={fuel.index} fuel={fuel} fuelTypeId={fuelTypeId} onSelectFuelTypeId={onSelectFuelTypeId} />
              )}
              <RadioLabel labelName={UIConstants.OtherFuels} />
              {fuelTypeDetails && fuelTypeDetails?.otherFuel?.map(fuel =>
                <RadioButton key={fuel.index} fuel={fuel} fuelTypeId={fuelTypeId} onSelectFuelTypeId={onSelectFuelTypeId} />
              )}
            </div>
          </div>}
      </div>}
      { isLoading ||
          <div style={styles.footerView} >
            <NavTracFooter
              onPressBack={onPressBack}
              onPressNext={onSubmit}
              checkIn={direction === 'outgated' ? UIConstants.checkOUT : UIConstants.checkIn}
              error={error}
              lang={language}
            />
          </div>
      }
    </div >
  );
};

const styles = {
  radioStyle: { marginRight: 10, display: 'flex', marginTop: 5 },
  gasolineView: { display: 'flex', padding: 10, paddingTop: 15, float: 'right' },
  radioContainer: { paddingTop: 15 },
  radioView: { display: 'flex', padding: 10, margin: '0 auto', width: '70%' },
  footerView: { marginTop: 80, width: '100%' },
}

export default FuelTypeDetails;
