import React from 'react';
import { UIConstants as UIConstantsEnglish, UIConstantsSpanish } from '../../constants'

export const Validator = ({ validatorKey, lang='en' }) => {
    const UIConstants = lang === 'en' ?  UIConstantsEnglish : UIConstantsSpanish
    return (
        <div style={style.mainContainer}>
            {validatorKey === UIConstants.ValidationKey.fuelType ?
                <label style={style.validationText}>
                    {UIConstants.fuelTypeValidationMsg}
                </label> :
                <label style={style.validationText}>
                    {UIConstants.validationText}  {validatorKey === UIConstants.ValidationKey.phoneNumber ? UIConstants.valid + validatorKey : validatorKey}
                </label>}
        </div>
    );
};

const style = {
    mainContainer: { display: 'flex', justifyContent: 'center', width: '100%' },
    validationText: { width: '90%', paddingTop: 10, fontSize: 13, color: 'rgb(255,0,0)', paddingLeft: 10, fontFamily: 'RobotoNormal' }
}
