import { UIConstants as UIConstantsEnglish, UIConstantsSpanish } from '../../constants';
const qs = require('qs');

export const getFuelTypes = (fuelData, fuelLabels, styles, language = 'en') => {
    let otherFuel = []
    let carbonFuel = [];
    let nearZeroEmission = [];
    let zeroEmission = [];
    const subFuelsEng = UIConstantsEnglish.FuelSubTypes;

    Object.values(fuelData).map(fuel => {
        const engFuel = Object.fromEntries(Object.entries(subFuelsEng).filter(([key, value]) => value === fuel.name));
        const engFuelKey = Object.keys(engFuel)[0];
        let fuelLabel = ''
        if (language === 'es') {
            fuelLabel = UIConstantsSpanish.FuelSubTypes[engFuelKey]
        } else {
            fuelLabel = engFuel[engFuelKey]
        }
        if (fuel.category === "Carbon fuel" && fuel.name === "Diesel") {
            carbonFuel.push({
                radioViewStyle: styles.radioView,
                radioStyle: styles.radioStyle,
                buttonName: fuel.name,
                buttonLabel: fuelLabel,
                buttonId: fuel.id,
                category: fuel.category,
                index: fuel.id
            })
        } else {
            otherFuel.push({
                radioViewStyle: styles.radioView,
                radioStyle: styles.radioStyle,
                buttonName: fuel.name,
                buttonLabel: fuelLabel,
                buttonId: fuel.id,
                category: fuel.category,
                index: fuel.id

            })
        }
        return null
    })

    return { carbonFuel, otherFuel, nearZeroEmission, zeroEmission }
}

export const getCompanyId = (details) => {
    return qs.parse(details.search, { ignoreQueryPrefix: true }).companyId
}

export const getSiteId = (details) => {
    return qs.parse(details.search, { ignoreQueryPrefix: true }).siteId
}
