import React, {useEffect, useState} from 'react';
import { UIConstants } from '../../constants/UIConstants';
import { Colors } from '../../constants/Colors';

export const NavTracHeader = ({
								  pageName,
								  companyName='',
								  siteName= '',
								  siteAddress= '',
								  error = false,
								  onSetLanguage,
								  language = 'en',
								  direction = '',
								  showLanguagePick=true,
								  checkinSite = null
	}) => {

	const [isSpanish, onSetIsSpanish] = useState(false);
	const onIsSpanishChange = (event) => {
		const isSpanish = event.target.id === 'isSpanish'
		onSetIsSpanish(isSpanish)
		onSetLanguage(isSpanish ? 'es' : 'en')
	}
	useEffect(() => {
		const isSpanish = language === 'es'
		onSetIsSpanish(isSpanish)
	}, [language]);

	return (
		<div>
			{showLanguagePick && <div className="flex justify-center items-center language-type" style={style.languagePick}>
				<div className="bg-gray-200 text-center self-center">
					<div className="inline-flex">
						<input type="radio" name="lang_type" id="isEnglish" hidden checked={isSpanish ? false: 'checked'} onChange={onIsSpanishChange}/>
						<label htmlFor="isEnglish" className="radio text-center self-center py-3 px-3 cursor-pointer hover:opacity-75">ENGLISH</label>
					</div>
					<div className="inline-flex">
						<input type="radio" name="lang_type" id="isSpanish" hidden checked={isSpanish ? 'checked': false} onChange={onIsSpanishChange}/>
						<label htmlFor="isSpanish" className="radio text-center self-center py-3 px-3 cursor-pointer hover:opacity-75">ESPAÑOL</label>
					</div>
				</div>
			</div>}

			<div className="grid grid-cols-1 justify-center items-center" style={style.logoContainer}>
				<div className="grid-rows-1 block justify-center items-center">
					<div className="justify-center items-center">
						{checkinSite && checkinSite.settings?.isMultiTenant ? checkinSite.meta.address : companyName + ' - ' + siteName }
					</div>
				</div>
				<div className="grid-rows-2 block justify-center items-center" style={style.addressContainer}>
					{checkinSite && checkinSite.settings?.isMultiTenant ? '' : siteAddress}
				</div>
			</div>
			<div style={style.directionContainer} className="flex justify-center items-center">
				<div className="">
					<div className="inline-flex">
					</div>
				</div>
			</div>
			<div style={style.pageNameContainer}>
				{error ? <label style={style.errorView}>{UIConstants.apiErrorMsg}</label> : <label style={style.labelView}>{pageName}</label>}
			</div>
		</div>
	);
};

const style = {
	addressContainer: {
		fontSize: '14px',
		width: '100%',
		textAlign: 'center',
	},
	logoContainer: {
		backgroundColor: Colors.navBlue,
		textAlign: 'center',
		fontSize: '48px',
		color: '#ffffff',
		paddingTop: '15px',
		width: '100%',
		paddingBottom: '15px'},
	directionContainer: {
		display: 'none',
		width: '100%',
		justifyContent: 'center',
		backgroundColor: '#2D9CDB' ,
		color: 'white',
		fontWeight: 600
	},
	languagePick: { display: 'absolute',
		width: '100%',
		justifyContent: 'center',
		backgroundColor: Colors.navBlue,
		fontSize: '18px'

	},
	logoView: { padding: 25, alignSelf: 'center', cursor: 'pointer' },
	pageNameContainer: { display: 'flex', width: '100%', justifyContent: 'center' },
	labelView: {
		color: Colors.navBlue,
		marginTop: 50,
		marginBottom: 15,
		fontSize: '30px',
		fontWeight: 'bold',
		fontFamily: 'Roboto',
	},
	errorView: { color: Colors.red, marginTop: 50, marginBottom: 15, fontSize: '22px', fontWeight: 'bold' },
};
