import React from 'react';
import { Colors, UIConstants as UIConstantsEnglish, UIConstantsSpanish } from '../../constants';
import logo from '../../assets/images/NavTracLogo.png';

export const NavTracFooter = ({ onPressBack, onPressNext, checkIn = false, error = false, lang='en' }) => {
    const UIConstants = lang === 'en' ? UIConstantsEnglish : UIConstantsSpanish
    return (
        <div>
            {onPressBack && <div style={styles.backContainer} className='mb-10'>
                <label onClick={onPressBack}
                    style={styles.backLabel}>
                    {UIConstants.Back}
                </label>
            </div>}

            {onPressNext && !error && <div style={styles.nextContainer} className='mb-10'>
                <label onClick={onPressNext}
                    style={checkIn ? styles.checkInLabel : styles.nextLabel}>
                    {checkIn ? checkIn : UIConstants.Next}
                </label>
            </div>}
            <div style={styles.logoContainer}>
                <div className="flex language-type">
                    <img src={logo} alt={logo} style={styles.logoView}></img>
                </div>
            </div>
        </div>

    )
}

const styles = {
    logoContainer: {
        display: 'flex',
        width: '100%',
        backgroundColor: Colors.navBlue,
        bottom: '0px' },
    logoView: { padding: 5, alignSelf: 'center', cursor: 'pointer', maxWidth:'100px' },
    backContainer: { flex: 'display', float: 'left', marginLeft: '40px'},
    backLabel: {
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '22px',
        color: Colors.lightGray,
        textAlign: 'right',
        alignSelf: 'right',
        cursor: 'pointer',
        fontFamily: 'RobotoNormal',
        bottom: '80px',
        left: '30px',
    },
    nextContainer: { flex: 'display', float: 'right', marginRight: '40px'},
    nextLabel: {
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '22px',
        color: Colors.labelBlue,
        textAlign: 'right',
        alignSelf: 'right',
        cursor: 'pointer',
        fontFamily: 'RobotoNormal',
        bottom: '80px',
        right: '30px',
    },
    checkInLabel: {
        fontWeight: '900',
        fontSize: '20px',
        lineHeight: '18px',
        color: Colors.labelBlue,
        textAlign: 'right',
        alignSelf: 'right',
        paddingRight: 40,
        cursor: 'pointer',
        fontFamily: 'RobotoNormal'
    }
}
