const qs = require('qs');

export const onGetQueryString = (details) => {
  const values = qs.parse(details, { ignoreQueryPrefix: true })
  if (values.siteId) {
    return `siteId=${values.siteId}`;
  }
  return `site=${values.site}`;
}

export const onGetQueryValues = (details) => {
  const values = qs.parse(details.search, { ignoreQueryPrefix: true })
  return {
    siteNumber: values.site || null,
    siteId: values.siteId || null,
    direction: values.direction || 'ingated',
  };
}

export const getDirection = () => {
  const domain = window.location.hostname.split('.')
  return domain[0] === 'checkout' ? 'outgated' : 'ingated';
}

export const onCheckQueryParams = (details) => {
  const values = qs.parse(details.search, { ignoreQueryPrefix: true })
  if (!values.siteId && !values.site) { return true }

  return false
}
