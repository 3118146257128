const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5000/'

export const submitCheckin = async (driverData) => {
    const meta = {files: [], language: 'en'}
    let appointmentAt = null
    if (driverData.appointmentDate) {
        try {
            appointmentAt = driverData.appointmentDate.toISOString()
        } catch (e) {
            appointmentAt = null
        }

    }
    const data = {
        firstName: driverData.firstName,
        lastName: driverData.lastName,
        phoneNumber: driverData.phoneNumber.replace(/[^\d]/g, ""),
        vehicleLicensePlate: driverData.tractorLicensePlate || '',
        loadNumber: driverData.loadNumber || '',
        assetNumber: driverData.assetNumber || '',
        chassisNumber: driverData.chassisNumber || '',
        carrierName: driverData.haulingCompanyName || '',
        customerName: driverData.customerName || '',
        securitySeal: driverData.securitySeal || '',
        fuelTypeId: driverData.fuelTypeId || null,
        companyId: driverData.companyId,
        siteId: driverData.siteId,
        locationId: driverData.locationId,
        statusId: driverData.statusId,
        meta: driverData.meta || meta,
        tags: driverData.tags || [],
        appointmentAt,
    }
    return post({ data }, 'driver_checkins');
}

export const post = async (driverDetails, path) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(driverDetails)
    };

    const response = await fetch(`${BASE_URL}${path}`, requestOptions);
    const data = await response.json();
    console.log("data", data)
    return data;
}


export const get = async (path) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const response = await fetch(`${BASE_URL}${path}`, requestOptions);
    const data = await response.json();

    return data;
}


