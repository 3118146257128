import { UIConstants } from '../constants/UIConstants';

export const validateText = (field) => {
    switch (field?.placeholder) {
        case UIConstants.DriverInfo.firstName:
            return (/[^a-zA-Z0-9 ]/).test(field.value);
        case UIConstants.DriverInfo.lastName:
            return (/[^a-zA-Z0-9 ]/).test(field.value);
        case UIConstants.DriverInfo.phoneNumber:
            const phoneNumber = field.value.replace(/[^\d]/g, "");
            return !(phoneNumber?.length > 9)
        default:
            return !(/^(?!\s*$).+/).test(field.value);
    }
};
