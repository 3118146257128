export const UIConstants = {
    projName: 'Navtrac',
    pageOneIndex: '1/3',
    pageTwoIndex: '2/3',
    pageThreeIndex: '3/3',
    pageName: {
        driversCheckOut: "DRIVER CHECK OUT",
        driversCheckIn: "DRIVER CHECK IN",
        employeesCheckIn: "EMPLOYEE CHECK IN",
        loadInfo: "LOAD INFO",
        fuelType: "FUEL TYPE",
        checkInComplete: 'CHECK-IN COMPLETE!',
        checkOutComplete: 'CHECK-OUT COMPLETE!',
        loading: 'LOADING...'
    },
    InputType: {
        Text: 'text',
    },
    direction: {
        ingated: 'INBOUND',
        outgated: 'OUTBOUND',
    },
    DriverInfo: {
        firstName: 'First name',
        lastName: 'Last name',
        phoneNumber: 'Phone number',
        tractorLP: 'Tractor License Plate #',
        loadID: 'Load Number (Booking # or PO # or BOL #)',
        assetID: 'Container/Trailer Number',
        chassisNumber: 'Chassis Number',
        companyName: 'Company Name (Hauling Company)',
        customerName: 'Customer Name',
        fualType: 'Fuel Type',
        securitySeal: 'Security Seal Number',
        pickUp: 'Pick Up',
        dropOff: 'Drop Off',
        loaded: 'LOADED',
        emptyLoad: 'EMPTY',
        uploadDriverLicense: 'Upload Driver License (optional)',
        uploadPo: 'Upload PO/BOL Document',
        uploadSeal: 'Upload Security Seal',
        destination: 'Customer (Final Destination)',
        assetLength: "Trailer Length (53' or 48')"
    },
    FuelTypes: {
        carbonFuel: 'Carbon fuel',
        nearZeroEmission: 'Near Zero Emission',
        zeroEmission: 'Zero Emission',
    },
    OtherFuels: 'Other Types',
    FuelSubTypes: {
        diesel: 'Diesel',
        gasoline: 'Gasoline',
        cng: 'Compressed Natural Gas (CNG)',
        rng: 'Renewable Natural Gas (RNG)',
        propane: 'Propane',
        electricBattery: 'Electric battery',
        fuelCell: 'Fuel Cell (Hydrogen)'
    },
    Next: 'Next >',
    Back: '< Back',
    checkIn: 'CHECK-IN!',
    checkOUT: 'CHECK-OUT!',
    Submit: 'Submit',
    valid: 'valid ',
    ValidationKey: {
        firstname: 'first name',
        lastname: 'last name',
        phoneNumber: 'phone number ie. (111) 123-4567',
        tractorLP: 'tractor license plate number',
        loadID: 'load, booking or PO number',
        assetID: 'asset id number',
        companyName: 'hauling company name',
        customerName: 'customer name',
        securitySeal: 'security seal',
        fuelType: 'fuel Type',
        chassisNumber: 'chassis Number',
        destination: 'destination',
        assetLength: 'trailer length',
    },
    validationText: 'Please enter ',
    fuelTypeValidationMsg: 'Please select fuel type',
    thanksMessage: 'You have successfully checked in. Please wait in the street and we will send you a text when your dock is ready.',
    yardSuccessMessage: 'You have successfully checked in. You may proceed into the yard.',
    employeeSuccessMessage: 'You have successfully checked in.',
    yardOutgateSuccessMessage: 'You have successfully checked out. You may depart the yard.',
    helpLineNum: '',
    contactInfoMsg: 'We’ll text you with directions to your dock door.',
    companyIds: {
        test1: '017916e6-52ee-12f1-042f-3f79e45ff980',
        test2: '017916e6-5320-11c1-0591-f6b9f8f371c0',
        test3: '017916e6-533d-1dc1-0322-2bd26e7ecfa0',
        test4: '017916e6-53ff-1331-0358-134a49f94c00'
    },
    apiErrorMsg: 'Something went wrong...'
}
