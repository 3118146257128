
import React from 'react';
import { Colors } from '../../constants/Colors';

const RadioLabel = ({ labelName }) => {
    return (
        <div style={style.labelContainer}>
            <label style={style.labelText}>
                {labelName}
            </label>
        </div>
    );
};

const RadioButton = ({ fuel, fuelTypeId, onSelectFuelTypeId }) => {

    const onSetValue = (event) => {
        onSelectFuelTypeId(event.target.name)
    }
    return (
        <div 
        key={fuel?.index.toString()}
        style={fuel?.radioViewStyle}>
            <input
                key={fuel?.index.toString()}
                type="radio"
                value={fuel?.buttonName}
                name={fuel?.buttonId}
                style={fuel?.radioStyle}
                checked={fuelTypeId === fuel?.buttonId}
                onChange={onSetValue}
            />
            {fuel?.buttonLabel}
        </div>
    )
}

export { RadioLabel, RadioButton }

const style = {
    labelContainer: { display: 'flex', width: '100%', justifyContent: 'center', marginTop: 15 },
    labelText: {
        backgroundColor: Colors.borderGray,
        width: '100%',
        textAlign: 'center',
        color: Colors.black,
        fontSize: '23px',
        fontWeight: '500',
        lineHeight: '26.95px',
        padding: 15,
        fontFamily: 'RobotoMedium'
    }
}


