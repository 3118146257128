import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import { Colors, UIConstants as UIConstantsEnglish, UIConstantsSpanish } from '../../constants';
import { NavTracHeader } from '../../components/index';

const SuccessDetails = () => {

    let history = useHistory();
    let location = useLocation();
    const [direction, onSetDirection] = useState('ingated');
    const [UIConstants, onSetUIConstants] = useState(UIConstantsEnglish);
    const [checkinSite, onSetCheckinSite] = useState(null);
    const [checkinLocation, onSetCheckinLocation] = useState(null);
    const [language, onSetLanguage] = useState('en');
    const [successMessage, onSetMessage] = useState(UIConstants.thanksMessage);

      window.onpopstate = function (event) {
        history.go(1);
      };

    useEffect(() => {
        if (location?.state?.language !== 'en') {
            onSetUIConstants(UIConstantsSpanish)
        } else {
            onSetUIConstants(UIConstantsEnglish)
        }
        onSetLanguage(location?.state?.language)
        onSetDirection(location?.state?.direction)
        onSetCheckinSite(location?.state?.checkinSite)
        onSetCheckinLocation(location?.state?.checkinLocation)

        if (location?.state?.locationType === 'yard') {
            onSetMessage(UIConstants.yardOutgateSuccessMessage)
            if(direction === 'ingated') {
                onSetMessage(UIConstants.yardSuccessMessage)
            }
        }
        if (location?.state?.locationType === 'employee') {
            onSetMessage(UIConstants.employeeSuccessMessage)
        }
        if (location?.state?.checkinLocation?.meta?.customMessages || location?.state?.checkinLocation?.meta?.customMessage) {
            onSetMessage(location?.state?.checkinLocation.meta?.customMessages[location?.state?.language] || location?.state?.checkinLocation.meta?.customMessage)
        }

    }, [location?.state, UIConstants, direction]);

    return (
    <div>
      <NavTracHeader
        pageName={direction === 'ingated' ? UIConstants.pageName.checkInComplete : UIConstants.pageName.checkOutComplete}
        language={language}
        showLanguagePick={false}
        companyName={checkinSite?.company?.name || ''}
        checkinSite={checkinSite}
        siteName={checkinLocation?.name || checkinSite?.name || ''}
        siteAddress={checkinLocation?.meta?.address || checkinSite?.meta?.address}
      />
      <div style={style.successMsgContainer}>
        <span style={style.successMsgText}>
          {successMessage}
        </span>
      </div>

      <div style={style.helpLineContainer}>
        <span style={style.helpLineText}>
          {UIConstants.helpLineNum}
        </span>
      </div>
    </div >
    );
};

const style = {
  successMsgContainer: { display: 'flex', width: '100%', justifyContent: 'center', marginTop: '15%' },
  successMsgText: { textAlign: 'center', fontFamily: 'RobotoNormal', lineHeight: '30px', fontSize: '26px', color: Colors.labelBlue, fontWeight: '400', width: '65%' },
  helpLineContainer: { display: 'flex', width: '100%', justifyContent: 'center', marginTop: '15%' },
  helpLineText: { textAlign: 'center', fontFamily: 'RobotoNormal', lineHeight: '28px', fontSize: '24px', color: Colors.black, fontWeight: '400', width: '50%' }
}

export default SuccessDetails;
