import { UIConstants } from './UIConstants';

export const UIConstantsSpanish = {
    ...UIConstants,
    pageName: {
        driversCheckOut: "1. ‘Check-Out’ para Conductor",
        driversCheckIn: "1. ‘Check-In’ para Conductor",
        employeesCheckIn: "1. CHECK IN",
        loadInfo: "2. Información de Carga",
        fuelType: "3. Tipo de combustible",
        checkInComplete: '¡’Check-In’ completo!',
        checkOutComplete: '¡’Check-Out’ completo!',
        loading: 'sumisión...'
    },
    direction: {
        ingated: 'ENTRANTE',
        outgated: 'SALIDA',
    },
    DriverInfo: {
        firstName: 'Primer nombre',
        lastName: 'Apellido',
        phoneNumber: 'Número de teléfono',
        tractorLP: 'Tractor License Plate #',
        loadID: 'Número de carga (reserva # o PO # o BOL #)',
        assetID: 'Número de contenedor / remolque',
        chassisNumber: 'Número de Chasis (opcional)',
        companyName: 'Nombre de la empresa (empresa de transporte)',
        customerName: 'Nombre del cliente',
        fualType: 'Tipo de combustible',
        securitySeal: 'Número de sello de seguridad (opcional)',
        pickUp: 'Recoger',
        dropOff: 'Dejar',
        uploadDriverLicense: 'Subir Licencia de conducir (opcional)',
        uploadPo: 'Subir documento PO / BOL (opcional)',
        uploadSeal: 'Subir Sello de Seguridad',
        destination: 'Destino',
        assetLength: "contenedor longitud (53' or 48')"
    },
    FuelTypes: {
        carbonFuel: 'Combustible de carbono',
        nearZeroEmission: 'Emisión cercana a cero',
        zeroEmission: 'Sin emisión',
    },
    OtherFuels: 'Otros tipos',
    FuelSubTypes: {
        diesel: 'Diesel',
        gasoline: 'Gasolina',
        cng: 'Gas natural comprimido (CNG)',
        rng: 'Gas natural renovable (RNG)',
        propane: 'Propano',
        electricBattery: 'Batería eléctrica',
        fuelCell: 'Batería de hidrógeno'
    },
    Next: 'Siguiente >',
    Back: '< Atrás',
    checkIn: '¡Registrarse!',
    checkOUT: '¡Registrarse!',
    Submit: 'Submit',
    valid: 'valid ',
    ValidationKey: {
        firstname: 'Primer nombre',
        lastname: 'Apellido',
        phoneNumber: 'número de teléfono. (111) 123-4567',
        tractorLP: 'tractor license plate number',
        loadID: 'número de carga',
        assetID: 'asset id number',
        companyName: 'Nombre de la empresa',
        customerName: 'customer name',
        securitySeal: 'Número de sello de seguridad',
        fuelType: 'Tipo de combustible',
        chassisNumber: 'Número de Chasis',
    },
    validationText: 'Por favor escribe ',
    fuelTypeValidationMsg: 'Seleccione el tipo de combustible para su vehículo',
    thanksMessage: 'Su reserva se ha realizado correctamente. Espere en la calle y le enviaremos un mensaje de texto cuando su muelle de carga esté listo.',
    employeeSuccessMessage: 'Su reserva se ha realizado correctamente.',
    yardSuccessMessage: 'Su reserva se ha realizado correctamente. Puede proceder al patio de carga.',
    yardOutgateSuccessMessage: 'Su check-out se ha realizado correctamente. Puede salir del patio de carga.',
    helpLineNum: '',
    contactInfoMsg: 'Le enviaremos un mensaje de texto con las instrucciones para llegar a la puerta del muelle de carga.',
    companyIds: {
        test1: '017916e6-52ee-12f1-042f-3f79e45ff980',
        test2: '017916e6-5320-11c1-0591-f6b9f8f371c0',
        test3: '017916e6-533d-1dc1-0322-2bd26e7ecfa0',
        test4: '017916e6-53ff-1331-0358-134a49f94c00'
    },
    apiErrorMsg: 'Algo salió mal...'
};