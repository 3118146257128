import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Colors, UIConstants as UIConstantsEnglish, UIConstantsSpanish } from '../../constants';
import { NavTracHeader, Input, NavTracFooter, Validator } from '../../components/index';
import CloudinaryUploader from '../../components/CloudinaryUploader';
import {TRACKING_DETAILS_SCREEN, FUAL_TYPE_DETAILS_SCREEN, SUCCESS_DETAILS} from '../../constants/routes';
import {onGetQueryValues, getDirection} from '../../services/RouteServices';
import { setStorage, getStorage } from "../../services/LocalStorageServices";
import {get, submitCheckin} from '../../services/APIServices';
import spinner from '../../assets/images/BrokenWheel.gif';

const DriverDetails = () => {
  const STORAGE_KEY = 'DRIVER_DATA';
  const [isloading, onSetIsLoading] = useState(true);
  const [language, onSetLanguage] = useState('en');
  const [UIConstants, onSetUIConstants] = useState(UIConstantsEnglish);
  const [direction, onSetDirection] = useState('ingated');
  const [checkinType, onSetCheckinType] = useState('pickup');
  const [locationType, onSetLocationType] = useState('warehouse');
  const [firstName, onSetFirstName] = useState('');
  const [siteId, onSetSiteId] = useState('');
  const [checkinSite, onSetCheckinSite] = useState(null);
  const [checkinLocation, onSetCheckinLocation] = useState(null);
  const [companyId, onSetCompanyId] = useState('');
  const [driverLicenseImage, onSetDriverLicenseImage] = useState(null);
  const [isValidFirstName, onValidFirstName] = useState();
  const [lastName, onSetLastName] = useState('');
  const [isValidLastName, onValidLastName] = useState();
  const [phoneNumber, onSetPhoneNumber] = useState('');
  const [isValidPhoneNumber, onValidPhoneNumber] = useState();
  const [checkInStatus, onSetCheckInStatus] = useState();
  const [haulingCompanyName, onSetCompanyName] = useState('');
  const [isValidCompanyName, onValidCompanyName] = useState(false);
  const [isSubmitting, onSetIsSubmitting] = useState(false);
  const [footerText, onSetFooterText] = useState('Next >');
  const [initialStartTime, onSetInitialStartTime] = useState();

  const [loadID, onSetLoadId] = useState('');
  const [isValidLoadID, onValidLoadID] = useState(false);
  const [assetID, onSetAssetId] = useState('');
  const [isValidAssetID, onValidAssetID] = useState(false);
  const [destination, onSetDestination] = useState('');
  const [isValidDestination, onValidDestination] = useState(false);
  const [assetLength, onSetAssetLength] = useState('');
  const [isValidAssetLength, onValidAssetLength] = useState(false);

  const [isGeoCanCheckin, onSetIsGeoCanCheckin] = useState(true);
  const [customGeoDisabledMessage, onSetCustomGeoDisabledMessage] = useState('This site requires your location to be near the facility. Please try again when you are close');
  const [distance, onSetDistance] = useState('unknown');
  const [isLocationDisabledCheckin, onSetisLocationDisabled] = useState(false);
  const [isLocationDisabledMessage, onSetisLocationDisabledMessage] = useState('This location has disabled the check-in feature.');

  const [error, onGetError] = useState();

  let history = useHistory();
  let location = useLocation();
  let { siteNumber } = useParams();

  const onPressNext = async () => {
    if (firstName === '') {
      onValidFirstName(true)
    }
    else if (lastName === '') {
      onValidLastName(true)
    }
    /* eslint-disable no-useless-escape */
    else if (!(phoneNumber.replace(/[^\d]/g, "").length === 10)) {
      onValidPhoneNumber(true)
    }
    else if (haulingCompanyName === '' || typeof haulingCompanyName === 'undefined') {
      onValidCompanyName(true)
    }
    else if (loadID === '' && locationType === 'checkout_only') {
      onValidLoadID(true)
    }
    else if (assetID === '' && locationType === 'checkout_only') {
      onValidAssetID(true)
    }
    else if (destination === '' && locationType === 'checkout_only') {
      onValidDestination(true)
    }
    else if (assetLength === '' && locationType === 'checkout_only') {
      onValidAssetLength(true)
    }
    else {
      let driverDetails = {
        ...location?.state,
        firstName,
        lastName,
        phoneNumber,
        checkInStatus,
        driverLicenseImage,
        siteId,
        companyId,
        statusId: checkInStatus[direction] || checkInStatus['ingated'],
        locationId: checkinLocation.id,
        checkinType,
        locationType,
        language,
        direction,
        checkinSite,
        checkinLocation,
        haulingCompanyName,
        siteNumber,
        loadNumber: loadID,
        assetNumber: assetID,
        customerName: destination,
        meta: {files: [], language: language, assetLength, initialStartTime},
        tags: [checkinType || 'pickup'],
      }
      setStorage(STORAGE_KEY, driverDetails)
      if (locationType === 'employee' || locationType === 'checkout_only') {
        try {
          if (isSubmitting) {
            return true
          }
          await onSetIsSubmitting(true)
          let response = await submitCheckin(driverDetails)
          if (typeof response?.data === 'object' && response?.data !== null) {
            history.push(`${SUCCESS_DETAILS}`, { ...location.state, ...driverDetails });
          }
          else {
            onGetError(true)
          }
          await onSetIsSubmitting(false)
        } catch (error) {
          onGetError(true)
          await onSetIsSubmitting(false)
        }
      } else {
        if (checkinType === 'ltl') {
          history.push(`${FUAL_TYPE_DETAILS_SCREEN}?siteId=${checkinSite.id}`, driverDetails);
        } else {
          history.push(`${TRACKING_DETAILS_SCREEN}?siteId=${checkinSite.id}`, driverDetails);
        }
      }

    }
  }

  const onCheckinTypeChange = (event) => {
    onSetCheckinType(event.target.value)
  }

  const onPhoneChange = (phone) => {
    function formatPhoneNumber(value) {
      if (!value) return value;
      const phoneNumber = value.replace(/[^\d]/g, "");

      const phoneNumberLength = phoneNumber.length;
      if (phoneNumberLength < 4) return `(${phoneNumber.slice(0, 3)}`;
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
    onSetPhoneNumber(formatPhoneNumber(phone))
  }

  const onLanguageChange = (lang) => {
    onSetLanguage(lang)
    if (lang !== 'en') {
      onSetUIConstants(UIConstantsSpanish)
    } else {
      onSetUIConstants(UIConstantsEnglish)
    }
  }

  const isFieldRequired = (field) => {
    if(checkinLocation && checkinLocation.meta?.required && checkinLocation.meta?.required?.indexOf(field) >= 0) {
      return true
    }
    return false
  }

  const calDistance = (lat1, lon1, lat2, lon2, unit) => {
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    }
    else {
      const radlat1 = Math.PI * lat1/180;
      const radlat2 = Math.PI * lat2/180;
      const theta = lon1-lon2;
      const radtheta = Math.PI * theta/180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit==="K") { dist = dist * 1.609344 }
      if (unit==="N") { dist = dist * 0.8684 }
      return dist;
    }
  }

  useEffect(() => {
    const queryValues = onGetQueryValues(location)
    onSetDirection(getDirection())
    onGetError(!queryValues.siteNumber && !queryValues.siteId && !siteNumber)

    const getLocationData = async () => {
      try {
        let checkinLocation
        let site
        if(queryValues.siteNumber || siteNumber) {
          const alias = queryValues.siteNumber || siteNumber
          checkinLocation = await get(`locations/${alias}/?include=[site.[locations,company]]`)
          checkinLocation = checkinLocation.data
          onSetCheckinSite(checkinLocation.site)
        }
        else if (queryValues.siteId) {
          site = await get(`sites/${queryValues.siteId}/?include=company,locations`)
          site = site.data
          onSetCheckinSite(site)
          checkinLocation = site.locations[0]
        }

        onSetCheckinLocation(checkinLocation)
        onSetSiteId(checkinLocation?.site?.id || site?.id)
        onSetCompanyId(checkinLocation?.site?.companyId || site.companyId)
        onSetLocationType(checkinLocation?.meta?.type || 'warehouse')
        onSetCheckinType(checkinLocation?.meta?.type === 'yard' ? 'loaded' :  'pickup')
        onSetisLocationDisabled(checkinLocation?.meta?.disabled || false)
        onSetisLocationDisabledMessage(checkinLocation?.meta?.disabledMessage)

        if (checkinLocation?.meta?.type === 'employee') {
          onSetFooterText('Submit')
        }
        if (checkinLocation?.meta?.type === 'checkout_only') {
          onSetFooterText('CHECK-IN!')
        }
        if (checkinLocation?.meta?.geo?.isGeoFenceEnabled === true) {
          const calculateGeoDistance = (position) => {
            if(position && position.coords) {
              console.log('current position', position.coords)
              console.log('facility position', checkinLocation?.meta?.geo)
              const distance = calDistance(position.coords.latitude, position.coords.longitude, checkinLocation?.meta?.geo.coordinates.lat, checkinLocation?.meta?.geo.coordinates.long, "M")
              console.log('distance in miles', distance, 'and requires max of', checkinLocation?.meta?.geo.maxDistance, 'mile(s)')
              if (distance > checkinLocation?.meta?.geo.maxDistance) {
                onSetIsGeoCanCheckin(false)
                if (checkinLocation?.meta?.geo.customErrorMessage) {
                  onSetCustomGeoDisabledMessage(checkinLocation?.meta?.geo.customErrorMessage)
                }
                onSetDistance(`${distance.toFixed(2)} mile(s)`)
              }
            } else {
              onSetIsGeoCanCheckin(false)
            }
            onSetIsLoading(false)
          }
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(calculateGeoDistance, (error) => {
              onSetIsGeoCanCheckin(false)
              onSetIsLoading(false)
            });
          } else {
            onSetIsLoading(false)
            onSetIsGeoCanCheckin(false)
          }
        } else {
          onSetIsLoading(false)
        }
      } catch (error) {
        console.log(error)
        onGetError(true)
      }
    }

    const getCheckInStatus = async () => {
      try {
        const statusKeys = {}
        const checkInStatus = await get('checkin_statuses')
        checkInStatus.data.map(status => statusKeys[status.key] = status.id)
        onSetCheckInStatus(statusKeys)
      } catch (error) {
        onGetError(true)
      }
    }

    const savedData = getStorage('DRIVER_DATA')
    if (savedData) {
      onSetFirstName(savedData.firstName)
      onSetLastName(savedData.lastName)
      onSetPhoneNumber(savedData.phoneNumber)
      onSetCompanyName(savedData.haulingCompanyName)
    }
    if (typeof location?.state === 'object' && location?.state !== null) {
      onSetFirstName(location?.state?.firstName)
      onSetLastName(location?.state?.lastName)
      onSetPhoneNumber(location?.state?.phoneNumber)
      onSetCheckinType(location?.state?.checkinType)
      onSetDirection(location?.state?.direction)
      onSetCompanyName(location?.state?.haulingCompanyName)
      onLanguageChange(location?.state?.language)
    }
    getCheckInStatus();
    getLocationData()
    onSetInitialStartTime((new Date()).toISOString())
  }, [location, siteNumber])

  return (
    <div>
      <NavTracHeader
        pageName={direction === 'outgated' ? UIConstants.pageName.driversCheckOut : locationType !== 'employee' ? UIConstants.pageName.driversCheckIn : UIConstants.pageName.employeesCheckIn}
        companyName={checkinSite?.company?.name || ''}
        checkinSite={checkinSite}
        siteName={checkinLocation?.name || checkinSite?.name || ''}
        siteAddress={checkinLocation?.meta?.address || checkinSite?.meta?.address}
        error={error}
        onSetLanguage={onLanguageChange}
        language={language}
        direction={UIConstants.direction[direction]}
      />
      {!error && !isloading && isGeoCanCheckin && !isLocationDisabledCheckin && <div>
        { locationType !== 'employee' && <div className="flex justify-center items-center">
            <div className="bg-gray-200 rounded-lg">
              <div className="inline-flex rounded-lg">
                <input type="radio" name="checkin_type" id="isPickUp" value={locationType === 'yard' ? 'loaded' :  'pickup'} hidden checked={checkinType === 'pickup' || checkinType === 'loaded' ? 'checked' : false} onChange={onCheckinTypeChange}/>
                <label htmlFor="isPickUp" className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75">
                  {locationType === 'yard' ? UIConstants.DriverInfo.loaded :  UIConstants.DriverInfo.pickUp}
                </label>
              </div>
              <div className="inline-flex rounded-lg">
                <input type="radio" name="checkin_type" id="isDropOff" value={locationType === 'yard' ? 'empty' :  'dropoff'} hidden checked={checkinType === 'dropoff' || checkinType === 'empty' ? 'checked': false}  onChange={onCheckinTypeChange}/>
                <label htmlFor="isDropOff" className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75">
                  {locationType === 'yard' ? UIConstants.DriverInfo.emptyLoad : UIConstants.DriverInfo.dropOff}
                </label>
              </div>
              {locationType  !== 'yard' &&
              <div className="inline-flex rounded-lg">
                <input type="radio" name="checkin_type" id="isLtl" value="ltl" checked={checkinType === 'ltl' ? 'checked': false} hidden onChange={onCheckinTypeChange}/>
                <label htmlFor="isLtl" className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75">
                  LTL
                </label>
              </div>}
            </div>
          </div> }

        {locationType === 'checkout_only' &&
            <div style={styles.requiredText} className="flex justify-center items-center language-type">
              All fields are required.
            </div>
        }
        <Input
          labelName={UIConstants.DriverInfo.firstName}
          type={UIConstants.InputType.Text}
          value={firstName}
          onChangeText={onSetFirstName}
          onValid={onValidFirstName}
          isValidField={isValidFirstName}
        />
        {isValidFirstName && <Validator validatorKey={UIConstants.ValidationKey.firstname}  lang={language} />}

        <Input
          labelName={UIConstants.DriverInfo.lastName}
          type={UIConstants.InputType.Text}
          value={lastName}
          onChangeText={onSetLastName}
          onValid={onValidLastName}
          isValidField={isValidLastName}
        />
        {isValidLastName && <Validator validatorKey={UIConstants.ValidationKey.lastname} lang={language} />}

        <Input
          labelName={UIConstants.DriverInfo.phoneNumber}
          type={UIConstants.InputType.Text}
          value={phoneNumber}
          onChangeText={onPhoneChange}
          onValid={onValidPhoneNumber}
          isValidField={isValidPhoneNumber}
        />
        {isValidPhoneNumber && <Validator validatorKey={UIConstants.ValidationKey.phoneNumber} lang={language} />}
        <Input
            labelName={locationType !== 'employee' ?  locationType !== 'checkout_only' ? UIConstants.DriverInfo.companyName : 'Broker Name' : 'Employee Number'}
            type={UIConstants.InputType.Text}
            value={haulingCompanyName || ''}
            onChangeText={onSetCompanyName}
            onValid={onValidCompanyName}
            isValidField={isValidCompanyName}
        />
        {locationType !== 'employee' && isValidCompanyName && <Validator validatorKey={UIConstants.ValidationKey.companyName} lang={language} />}
        {locationType === 'checkout_only' && <div>
          <Input
              labelName={'Delivery# (Pick-Up#)'}
              type={UIConstants.InputType.Text}
              value={loadID || ''}
              onChangeText={onSetLoadId}
              onValid={onValidLoadID}
              isValidField={isValidLoadID}
          />
          {isValidLoadID && (locationType !== 'yard' || isFieldRequired('loadNumber')) && <Validator
              validatorKey={UIConstants.ValidationKey.loadID}
              lang={language}
          />}

          <Input
              labelName={UIConstants.DriverInfo.assetID}
              type={UIConstants.InputType.Text}
              value={assetID || ''}
              onChangeText={onSetAssetId}
              onValid={onValidAssetID}
              isValidField={isValidAssetID}
          />
          {isValidAssetID && <Validator validatorKey={UIConstants.ValidationKey.assetID} lang={language} />}

          <Input
              labelName={UIConstants.DriverInfo.destination}
              type={UIConstants.InputType.Text}
              value={destination || ''}
              onChangeText={onSetDestination}
              onValid={onValidDestination}
              isValidField={isValidDestination}
          />
          {isValidDestination && <Validator validatorKey={UIConstants.ValidationKey.destination} lang={language} />}

          <Input
              labelName={UIConstants.DriverInfo.assetLength}
              type={UIConstants.InputType.Text}
              value={assetLength || ''}
              onChangeText={onSetAssetLength}
              onValid={onValidAssetLength}
              isValidField={isValidAssetLength}
          />
          {isValidDestination && <Validator validatorKey={UIConstants.ValidationKey.assetLength} lang={language} />}

        </div>}


        {locationType !== 'checkout_only' &&
            <div style={styles.contactInfoContainer}>
              <CloudinaryUploader
                  cloudName='navtrac'
                  uploadPreset='pkoz2ikr'
                  folder={siteId}
                  buttonCaption={UIConstants.DriverInfo.uploadDriverLicense}
                  tags={['driver-license']}
                  language={language === 'en' ? 'en' : 'es'}
                  onUploadSuccess={(image)=>{
                    onSetDriverLicenseImage(image)
                  }}
              />
            </div>

        }
        <div style={styles.footerView} >
          <NavTracFooter
              onPressNext={onPressNext}
              lang={language}
              checkIn={footerText}
          />
        </div>

      </div>}
      {isloading && <div style={styles.geoDisabledView}>
        <div className="flex language-type">
          <img src={spinner} alt={spinner} style={styles.geoDisabledView}></img>
        </div>
      </div>}
      {!isloading && !isGeoCanCheckin && <div style={styles.geoDisabledView}>
        {customGeoDisabledMessage}
        <br/>
        <br/>
        Currently {distance} from check-in location. Please refresh page once you are closer.
      </div>}
      {!isloading && isLocationDisabledCheckin && <div style={styles.geoDisabledView}>
        {isLocationDisabledMessage}
        <br/>
        <br/>
      </div>}

    </div >
  );
};


const styles = {
  footerView: { marginTop: 80, width: '100%' },
  requiredText: { marginTop: 20, paddingTop: 20, fontSize: '20px', fontWeight: '400', color: Colors.red,},
  contactInfoContainer: { display: 'flex', width: '100%', justifyContent: 'center', marginTop: '8%' },
  geoDisabledView: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    color: Colors.red,
    marginBottom: 15,
    fontSize: '30px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    textAlign: 'center'
  },
  contactInfoText: { fontFamily: 'RobotoNormal', fontSize: '20px', lineHeight: '30px', textAlign: 'center', fontWeight: '400', color: Colors.labelBlue, width: '50%' }
}

export default DriverDetails;
