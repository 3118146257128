import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import DateTimePicker from 'react-datetime-picker'
import { UIConstants as UIConstantsEnglish, UIConstantsSpanish } from '../../constants';
import { NavTracHeader, Input, SelectInput, NavTracFooter, Validator, Loader } from '../../components';
import CloudinaryUploader from '../../components/CloudinaryUploader';
import {FUAL_TYPE_DETAILS_SCREEN, SUCCESS_DETAILS} from '../../constants/routes';
import { onGetQueryString } from '../../services/RouteServices';
import { submitCheckin } from "../../services/APIServices";


const TrackingDetails = () => {

  const [language, onSetLanguage] = useState('en');
  const [direction, onSetDirection] = useState('ingated');
  const [UIConstants, onSetUIConstants] = useState(UIConstantsEnglish);
  const [locationType, onSetLocationType] = useState('warehouse');
  const [securitySeal, onSetSecuritySeal] = useState('');
  const [isValidSecuritySeal, onValidSecuritySeal] = useState();
  const [bolImage, onSetBolImage] = useState(null);
  const [sealImage, onSetSealImage] = useState(null);
  const [siteId, onSetSiteId] = useState('');
  const [checkinSite, onSetCheckinSite] = useState(null);
  const [checkinLocation, onSetCheckinLocation] = useState(null);
  const [loadID, onSetLoadId] = useState('');
  const [isValidLoadID, onValidLoadID] = useState(false);
  const [assetID, onSetAssetId] = useState('');
  const [isValidAssetID, onValidAssetID] = useState(false);
  const [chassisNumber, onSetChassisNumber] = useState('');
  const [isValidChassisNumber, onValidChassisNumber] = useState(false);
  const [customerName, onSetCustomerName] = useState('');
  const [selectedLocationId, onSetLocationId] = useState('');
  const [isValidSelectedLocationId, onValidSelectedLocationId] = useState(false);
  const [locationOptions, onSetLocationOptions] = useState([]);
  const [isValidCustomerName, onValidCustomerName] = useState(false);
  const [error, onGetError] = useState(false);
  const [isLoading, onSetLoading] = useState(false);
  const [appointmentDate, onDateChange] = useState(null);

  let history = useHistory();
  let location = useLocation();

  const onPressNext = async () => {
    if ((loadID === '' || typeof loadID === 'undefined')
        && (locationType !== 'yard' || isFieldRequired('loadNumber'))
        && direction === 'ingated') {
      onValidLoadID(true)
    }
    else if (checkinSite.settings?.isMultiTenant && selectedLocationId === '') {
      onValidSelectedLocationId(true)
    }
    else {

      let driverDetails = location?.state
      driverDetails.tractorLicensePlate = ''
      driverDetails.loadNumber = loadID
      driverDetails.assetNumber = assetID
      driverDetails.bolImage = bolImage
      driverDetails.sealImage= sealImage
      driverDetails.securitySeal = securitySeal
      driverDetails.language = language
      driverDetails.customerName = customerName
      driverDetails.chassisNumber = chassisNumber
      driverDetails.appointmentDate = appointmentDate

      if(driverDetails.driverLicenseImage) {
        driverDetails.meta.files.push(driverDetails.driverLicenseImage)
      }
      if(driverDetails.bolImage) {
        driverDetails.meta.files.push(driverDetails.bolImage)
      }
      if(driverDetails.sealImage) {
        driverDetails.meta.files.push(driverDetails.sealImage)
      }
      if (checkinSite.settings?.isMultiTenant && selectedLocationId) {
        driverDetails.locationId = selectedLocationId
      }
      if (locationType === 'yard') {
        try {
          onSetLoading(true)
          let response = await submitCheckin(driverDetails)
          onSetLoading(false)
          if (typeof response?.data === 'object' && response?.data !== null) {
            history.push(`${SUCCESS_DETAILS}`, { ...location.state });
          }
          else {
            onGetError(true)
          }
        } catch (error) {
          onGetError(true)
        }
      } else {
        let queryString = onGetQueryString(location?.search);
        history.push(`${FUAL_TYPE_DETAILS_SCREEN}?${queryString}`, driverDetails)
      }
    }
  }

  const onPressBack = () => {
    let queryString = onGetQueryString(location?.search);
    if(checkinLocation) {
      history.push(`/${checkinLocation.alias}`, {...location?.state})
    } else {
      history.push(`/?${queryString}`, {...location?.state})
    }
  }

  const onLanguageChange = (lang) => {
    onSetLanguage(lang)
    if (lang !== 'en') {
      onSetUIConstants(UIConstantsSpanish)
    } else {
      onSetUIConstants(UIConstantsEnglish)
    }
  }
  const isFieldRequired = (field) => {
    if(checkinLocation && checkinLocation.meta?.required && checkinLocation.meta?.required?.indexOf(field) >= 0) {
      return true
    }
    return false
  }
  useEffect(() => {
    onSetSiteId(location?.state?.siteId)
    onLanguageChange(location?.state?.language)
    const setBackPresValue = async () => {
      if (typeof location?.state === 'object' && location?.state !== null) {
        const siteLocations = location?.state?.checkinSite.locations.map((v) => { return {value: v.id, name: v.name}})
        onSetLocationOptions(siteLocations)

        onSetLoadId(location?.state?.loadNumber)
        onSetAssetId(location?.state?.assetNumber)
        onSetSecuritySeal(location?.state?.securitySeal || '')
        onSetLanguage(location?.state?.language)
        onSetDirection(location?.state?.direction)
        onSetCheckinSite(location?.state?.checkinSite)
        onSetCheckinLocation(location?.state?.checkinLocation)
        onSetChassisNumber(location?.state?.chassisNumber)
        onSetCustomerName(location?.state?.customerName)
        onSetLocationType(location?.state?.locationType)
      }
    }
    setBackPresValue();
  }, [location?.state]);

  return (
    <div>
      <NavTracHeader
        pageName={UIConstants.pageName.loadInfo}
        onSetLanguage={onLanguageChange}
        language={language}
        showLanguagePick={false}
        error={error}
        direction={UIConstants.direction[direction]}
        companyName={checkinSite?.company?.name || ''}
        checkinSite={checkinSite}
        siteName={checkinLocation?.name || checkinSite?.name || ''}
        siteAddress={checkinLocation?.meta?.address || checkinSite?.meta?.address}
      />
      {isLoading ? <Loader /> : <div>
        {checkinSite && checkinSite.meta?.checkinType !== 'yard' &&
            <div style={styles.reactOuterContainer}>
              <div style={styles.reactCalendar}>
              <div style={styles.appointmentLabel}>
                Appointment Time:
              </div>
              <DateTimePicker onChange={onDateChange} value={appointmentDate} disableClock={true}/>
              </div>
            </div>
        }

        {checkinSite && !checkinSite.settings?.isMultiTenant &&
            <div>
              <Input
                  labelName={UIConstants.DriverInfo.customerName}
                  type={UIConstants.InputType.Text}
                  value={customerName || ''}
                  onChangeText={onSetCustomerName}
                  onValid={onValidCustomerName}
                  isValidField={isValidCustomerName}
              />
              {isValidCustomerName && <Validator validatorKey={UIConstants.ValidationKey.customerName} />}
            </div>

        }
        {checkinSite && checkinSite.settings?.isMultiTenant &&
            <div>
              <SelectInput
                  value={selectedLocationId}
                  placeholder={''}
                  onChangeText={onSetLocationId}
                  options={locationOptions}
                  onValid={onValidSelectedLocationId}
                  isValidField={isValidSelectedLocationId}
              />
              {isValidSelectedLocationId && <Validator validatorKey={UIConstants.ValidationKey.customerName} />}
            </div>
        }
        <Input
            labelName={UIConstants.DriverInfo.loadID}
            type={UIConstants.InputType.Text}
            value={loadID || ''}
            onChangeText={onSetLoadId}
            onValid={onValidLoadID}
            isValidField={isValidLoadID}
        />
        {isValidLoadID && (locationType !== 'yard' || isFieldRequired('loadNumber')) && <Validator
            validatorKey={UIConstants.ValidationKey.loadID}
            lang={language}
        />}

        <Input
            labelName={UIConstants.DriverInfo.assetID}
            type={UIConstants.InputType.Text}
            value={assetID || ''}
            onChangeText={onSetAssetId}
            onValid={onValidAssetID}
            isValidField={isValidAssetID}
        />
        {isValidAssetID && <Validator validatorKey={UIConstants.ValidationKey.assetID} lang={language} />}
        <Input
            labelName={UIConstants.DriverInfo.chassisNumber}
            type={UIConstants.InputType.Text}
            value={chassisNumber || ''}
            onChangeText={onSetChassisNumber}
            onValid={onValidChassisNumber}
            isValidField={isValidChassisNumber}
        />
        {isValidChassisNumber && <Validator validatorKey={UIConstants.ValidationKey.chassisNumber} lang={language} />}
        <Input
            labelName={UIConstants.DriverInfo.securitySeal}
            type={UIConstants.InputType.Text}
            value={securitySeal}
            onChangeText={onSetSecuritySeal}
            onValid={onValidSecuritySeal}
            isValidField={isValidSecuritySeal}
        />
        {isValidSecuritySeal && <Validator validatorKey={UIConstants.ValidationKey.securitySeal} lang={language} />}
        <div style={styles.uploadContainer}>
          <CloudinaryUploader
              cloudName='navtrac'
              uploadPreset='pkoz2ikr'
              folder={siteId}
              buttonCaption={UIConstants.DriverInfo.uploadPo}
              tags={['bol-doc', loadID, assetID]}
              language={language === 'en' ? 'en' : 'es'}
              onUploadSuccess={(image)=>{
                onSetBolImage(image)
              }}
          />
          <CloudinaryUploader
              cloudName='navtrac'
              uploadPreset='pkoz2ikr'
              folder={siteId}
              buttonCaption={UIConstants.DriverInfo.uploadSeal}
              tags={['security-seal', loadID, assetID]}
              language={language === 'en' ? 'en' : 'es'}
              onUploadSuccess={(image)=>{
                onSetSealImage(image)
              }}
          />
        </div>
      </div>}

      { isLoading ||
          <div style={styles.footerView} >
            <NavTracFooter
              onPressBack={onPressBack}
              onPressNext={onPressNext}
              checkIn={locationType !== 'yard' ? UIConstants.Next : direction === 'outgated' ? UIConstants.checkOUT : UIConstants.checkIn}
              lang={language}
            />
          </div>
      }
    </div >
  );
};


const styles = {
  uploadContainer: { display: 'flex', width: '100%', justifyContent: 'center', marginTop: '8%' },
  footerView: { marginTop: 80, width: '100%' },
  reactOuterContainer: {  display: 'flex', width: '100%', justifyContent: 'center' },
  reactCalendar: {
    display: 'flex',
    width: '90%',
    borderRadius: '8px',
    fontSize: '14px',
    padding: '2px',
    paddingLeft: '12px',
    backgroundColor: 'rgb(246, 246, 246)',
    border: '1px solid rgb(232, 232, 232)',
    boxSizing: 'border-box',
    fontFamily:'RobotoNormal'
  },
  appointmentLabel: { color: 'rgb(189, 189, 189)', lineHeight: '40px', marginRight: '5px'}
}

export default TrackingDetails;
