/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './assets/fonts/Roboto-Bold.ttf';
import './assets/fonts/Acme-Regular.ttf';
import './assets/fonts/Roboto-Regular.ttf';
import './assets/fonts/Roboto-Medium.ttf';
import './index.css';

ReactDOM.render(<App />, document.getElementById('root'));
