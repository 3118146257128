import React from 'react';
import { validateText } from '../../services/Validation';
import { Colors } from '../../constants';

export const SelectInput = ({ labelName, value, options, onChangeText, onValid, isValidField }) => {

    const handleChange = (event) => {
        const isValid = validateText(event.target);
        onValid(isValid)
        onChangeText(event.target.value)
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <select
                onChange={handleChange}
                value={value}
                placeholder={labelName}
                style={{
                    borderRadius: 8,
                    fontSize: 14,
                    padding: 12,
                    backgroundColor: Colors.backgroundGray,
                    fill: 'Solid',
                    width: '90%',
                    border: isValidField ? '1px solid red' : '1px solid #E8E8E8',
                    boxSizing: 'border-box',
                    marginTop: 22,
                    fontFamily: 'RobotoNormal'
                }}
            >
                <option value='' key=''>Select A Customer</option>
                {options.map((e, key) => {
                    return <option value={e.value} key={e.value}>{e.name}</option>;
                })}
            </select>
        </div>
    );
};
