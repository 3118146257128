import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { DRIVER_DETAILS_SCREEN, DRIVER_DETAILS_SCREEN_SITE, TRACKING_DETAILS_SCREEN, FUAL_TYPE_DETAILS_SCREEN, SUCCESS_DETAILS } from './constants/routes';
import DriverDetails from './pages/DriverDetails';
import TrackingDetails from './pages/TrackingDetails';
import FuelTypeDetails from './pages/FuelTypeDetails';
import SuccessDetails from './pages/SuccessDetails';


const App = () => (
  <Router>
    <Switch>
      <Route exact path={DRIVER_DETAILS_SCREEN} component={DriverDetails} />
      <Route exact path={TRACKING_DETAILS_SCREEN} component={TrackingDetails} />
      <Route exact path={FUAL_TYPE_DETAILS_SCREEN} component={FuelTypeDetails} />
      <Route exact path={SUCCESS_DETAILS} component={SuccessDetails} />
      <Route exact path={DRIVER_DETAILS_SCREEN_SITE} component={DriverDetails} />
    </Switch>
  </Router>
);

export default App;
